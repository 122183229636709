@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Nunito:wght@200;300;500;700&display=swap');
body {
    font-family: 'Inter', sans-serif;
    min-height: 100%;
    height: 100%;
    width: 100%;
    position: relative;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
