body {
    margin: 0;
}
.App {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9fafb;
    color: rgb(43, 43, 43);
    height: calc(100vh);
    width: calc(100vw);
    position: relative;
}
.blueLogo {
    display: none;
    width: 300px;
    height: 80px;
}
.FormPage {
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.success_message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px !important;
    width: 585px;
    height: 600px !important;
    text-align: center;
}
.FormPageLeft {
    width: 566px;
    height: 100vh;
    background-image: url('img/FormBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: 10px;
    background-color: #2d6bf8;
}
.FormPageLeftHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}
.input_valid {
    border: solid 1px #e3e5f0 !important;
}
.input_invalid {
    border: solid 1px #ff496b !important;
}
.FormPageLeftList {
    margin-top: 30px;
    width: 420px;
    padding-left: 40px;
}
.FormPageRight {
    width: calc(100vw - 566px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.FormPageRightForm {
    width: 585px;
    height: 600px !important;
    display: flex;
    padding-top: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e3e5f0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}
.FormPageRightFormTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */
    letter-spacing: 0.005em;

    width: 505px;

    /* Coal */
    color: #25282d;
    margin-top: -80px;
    margin-bottom: 20px;
}
.FormPageRightOrderDemo {
    width: 585px;
    height: 210px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
    background-image: url('img/webcam.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.FormPageRightOrderDemo svg {
    margin-left: -30px;
    margin-bottom: 10px;
}
.ghostBtn {
    border: solid 1px #ffffff;
    width: 500px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
}
.ghostBtn:hover {
    border: solid 1px #ffffff;
    background-color: #2d6af86d;
    width: 500px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 4px;
}
select {
    /* styling */
    background-color: white;
    border: thin solid #e3e5f0;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.form-item {
    margin-top: 14px;
    height: 80px;
}
.form-item input {
    width: 500px;
    height: 44px;

    padding-left: 15px;
}
.form-item select {
    width: 520px;
    height: 44px;
    background-image: linear-gradient(45deg, transparent 50%, blue 50%),
        linear-gradient(135deg, blue 50%, transparent 50%), linear-gradient(to right, skyblue, skyblue);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
}
.form-item-half {
    margin-top: 14px;
    margin-right: 10.5px;
}
.form-item-half input {
    width: 233px;
    height: 44px;
    border: 1px solid #e4e6f2;
    padding-left: 15px;
}
.form-item-half select {
    width: 233px;
    height: 44px;
    border: 1px solid #e4e6f2;
    padding-left: 15px;
}
.form-label {
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 20px */
    letter-spacing: 0.005em;
    padding-left: 2px;
    /* Coal */
    color: #25282d;
}
.submit-btn {
    width: 515px;
    height: 44px;
    background: #2d6bf8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border: solid 1px #f9fafb;
    border-radius: 4px;
    font-weight: 550;
    font-size: 14px;
    line-height: 140%;
    cursor: pointer;
    /* identical to box height, or 20px */
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.005em;
    margin-top: 30px;
    margin-bottom: 10px;
    /* Vapor */
    color: #ffffff;
}
.submit-btn:hover {
    width: 515px;
    height: 44px;
    background: #6491fb;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border: solid 1px #f9fafb;
    border-radius: 4px;
    font-weight: 550;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.005em;
    margin-top: 30px;
    margin-bottom: 10px;
    /* Vapor */
    color: #ffffff;
}
.submit-btn:disabled {
    width: 515px;
    height: 44px;
    background: #c5d6ff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-weight: 550;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.005em;
    margin-top: 30px;
    margin-bottom: 10px;
    /* Vapor */
    color: #8d8d8d;
}
.submit button:focus-visible {
    border: solid 10px rgb(0, 255, 0);
}
.form-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 525px;
}
.error-label {
    color: rgb(255, 119, 119);
    font-size: 11px;
    padding-left: 5px;
}

@media screen and (max-width: 1150px) {
    .blueLogo {
        display: block;
        width: 300px;
        height: 120px;
    }
    .FormPageLeft {
        width: 0px;
        height: 0px;
        background-image: url('img/FormBG.png');
        background-size: cover;
        background-repeat: no-repeat;
        padding-left: 10px;
        background-color: #2d6bf8;
        overflow: hidden;
    }
    .FormPageRight {
        width: calc(100vw - 20px);
        height: calc(100vh - 20px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}
@media screen and (max-width: 500px) {
    .blueLogo {
        display: block;
        width: 300px;
    }
    .FormPageRight {
        margin-left: -5px !important;
        width: 100vw;
        height: calc(100vh) !important;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 20px;
        padding-left: 0px;
    }
    .FormPageRightForm {
        width: 350px;
        height: calc(100vh) !important;
        max-height: 700px;
        display: flex;
        padding-top: 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        border: 1px solid #e3e5f0;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        margin-top: -0px;
    }
    .form-grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 325px;
    }
    .FormPageRightFormTitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        /* identical to box height */
        letter-spacing: 0.005em;
        padding-top: 20px;
        width: 305px;

        /* Coal */
        color: #25282d;
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .FormPageRightOrderDemo {
        width: 390px;
        height: 210px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
        background-image: url('img/webcam.png');
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .FormPageRightOrderDemo svg {
        margin-left: -10px;
        margin-bottom: 10px;
        transform: scale(0.7);
    }
    .ghostBtn {
        border: solid 1px #ffffff;
        width: 300px !important;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }
    .ghostBtn:hover {
        border: solid 1px #ffffff;
        background-color: #2d6af86d;
        width: 200px !important;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 4px;
    }

    .form-item input {
        width: 300px;
        height: 44px;
        border: 1px solid #e4e6f2;
        padding-left: 15px;
    }
    .form-item select {
        width: 320px;
        height: 44px;
        border: 1px solid #e4e6f2;
        padding-left: 15px;
    }
    .form-item-half {
        margin-top: 10px;
        margin-right: 0;
    }
    .form-item-half input {
        width: 300px;
        height: 44px;
        border: 1px solid #e4e6f2;
        padding-left: 15px;
    }
    .submit-btn {
        width: 315px;
        height: 44px;
        background: #2d6bf8;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        font-weight: 550;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.005em;
        margin-top: 30px;
        margin-bottom: 10px;
        /* Vapor */
        color: #ffffff;
    }
    .submit-btn:disabled {
        width: 315px;
        height: 44px;
        background: #2d6bf8;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        font-weight: 550;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.005em;
        margin-top: 30px;
        margin-bottom: 10px;
        /* Vapor */
        color: #ffffff;
    }
    .success_message {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 40px !important;
        width: 350px;
        height: calc(100vh) !important;
        max-height: 700px;
        text-align: center;
    }
}

.TrialHeroText,
.TrialSidebarList {
    color: white;
    font-weight: 300;
}
.TrialHeroText {
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.9px;
    margin-top: 120px;
    max-width: 440px;
    padding: 0 50px;
}
span.orange-x {
    color: orange;
}
.TrialSidebarList {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    letter-spacing: 1px;
    margin-top: 40px;
    padding-left: 50px;
}
.TrialSidebarListEl {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}
.CheckboxText {
    margin-left: 6px;
}
.CopyrightFooter {
    background: #ededed;
    bottom: 0;
    border-top: 1px solid rgba(37, 40, 45, 0.25);
    color: rgba(37, 40, 45, 0.5);
    font-size: 12px;
    line-height: 1;
    margin: 0;
    position: fixed;
    padding: 20px 0;
    text-align: center;
    width: 100%;
}
